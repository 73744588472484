import _get from 'lodash/get';

import { getPathSRP } from '@/utilities';

import { getKeys, translateKeys } from '@atc/bonnet-parameters';
import { getIndexedLocationFilters } from '@atc/bonnet-reference';

import {
    flpCitiesCrawlPathsDuck,
} from '@/ducks/flp';

// update a collection of data with  a CS formatted path
// using a supplied query and dynamic parameter value
const updateWithPath = async ({ data = [], query = {}, brand }) => {
    const buildPromises = data.map(async (item) => {
        if (item.codes) {
            if (item.codes.startYear && item.codes.endYear) {
                item.codes.startYear = item.codes.startYear[0];
                item.codes.endYear = item.codes.endYear[0];
            }
        }

        const queryCopy = { ...query, city: item.city, state: item.state };
        if (queryCopy.zip) {
            delete queryCopy.zip;
        }

        const path = await getPathSRP({
            ...translateKeys(queryCopy, { target: 'lsc' }),
            ...item.codes,
        }, {
            basePath: true,
            brand,
        });

        return {
            ...item,
            link: path,
        };
    });

    return Promise.all(buildPromises);
};

// utility to inspect if there is crawlPath data to parse
// if not then reset the crawlPath state to hide the data
// essentially used to hide the crawl path on subsequent srp renders from filter changes
const processCrawlPathData = async ({ ctx, duck, dataPath, storeKey, query, lists }) => {
    const data = _get(ctx.data, dataPath, false);

    if (data) {
        // update the data with paths built by bonnet-paths
        const updatePromises = lists.map(async (key) => ({ [key]: await updateWithPath({ data: data[key], query, brand: ctx.data.brand }) }));
        const updatedLists = await Promise.all(updatePromises);

        // update the data set with the updated lists with paths
        const crawlPathData = {
            ...data,
            ...updatedLists.reduce((acc, list) => ({ ...acc, ...list }), {}),
        };
        // add the data to a batch action to be dispatched later
        return ctx.store.dispatch(duck.creators.setKey(storeKey, crawlPathData));
    }

    return ctx.store.dispatch(duck.creators.reset());
};

export default function withCrawlPath() {
    return async (ctx) => {
        const target = 'lsc';
        const listingTypeKey = getKeys('listingType')[target];
        const makeCodeKey = getKeys('makeCode')[target];
        const modelCodeKey = getKeys('modelCode')[target];
        const trimCodeKey = getKeys('trimCode')[target];
        const styleCodeKey = getKeys('vehicleStyleCodes')[target];
        const { brand } = ctx.data;

        const queryList = {
            [listingTypeKey]: ctx.query[listingTypeKey],
            [makeCodeKey]: ctx.query[makeCodeKey],
            [modelCodeKey]: ctx.query[modelCodeKey],
            [trimCodeKey]: ctx.query[trimCodeKey],
            [styleCodeKey]: ctx.query[styleCodeKey],
            city: ctx.query.city,
            state: ctx.query.state,
            zip: ctx.query.zip,
            startYear: ctx.query.startYear,
            endYear: ctx.query.endYear,
            fuelTypeGroup: ctx.query.fuelTypeGroup,
        };

        let indexedZip = true;
        // Only location pages with zip code or city center zip code in list should show crawlpaths (city crawlpath the exception)
        if (ctx.data.isIndexedZip !== undefined) {
            indexedZip = ctx.data.isIndexedZip;
        } else if (ctx.query.zip) {
            const { success } = await getIndexedLocationFilters(ctx.query, brand);

            if (!success) {
                indexedZip = false;
            }
        }

        const cityPath = 'citiesCrawlPaths';
        const cityList = ['links'];

        if (indexedZip) {

            // process cities crawlpath
            await processCrawlPathData({
                ctx,
                duck: flpCitiesCrawlPathsDuck,
                dataPath: cityPath,
                storeKey: 'citiesData',
                lists: cityList,
                query: queryList,
            });

        } else {
            // process cities crawlpath for non-canonical pages
            await processCrawlPathData({
                ctx,
                duck: flpCitiesCrawlPathsDuck,
                dataPath: cityPath,
                storeKey: 'citiesData',
                lists: cityList,
                query: queryList,
            });
        }

    };
}
