import { fetchJSON } from '@bonnet/next/fetch';

import { translateKeys } from '@atc/bonnet-parameters';

import { buildFordLandingPageData } from './flp/buildFordLandingPageData';

export default function addFordLandingPageData() {
    return async (ctx) => {

        if (typeof window === 'undefined') {
            const lscSearchOptional = translateKeys(ctx.data.requestOptions.optionalRequestLSC.query, { target: 'lsc' });
            const lscRequestOptional = {
                ...ctx.data.requestOptions.optionalRequestLSC,
                query: lscSearchOptional,
            };
            const crawlPathsRequestOptions = lscRequestOptional;
            const citiesCrawlPathsEndpoint = '/rest/lsc/crawl/cities';

            ctx.fetchCompositeJSON.addFetches({
                citiesCrawlPaths: fetchJSON(citiesCrawlPathsEndpoint, crawlPathsRequestOptions),
            });
        }

        Object.assign(ctx.data, await buildFordLandingPageData(ctx.query));

        const { base = {}, summaryContent = {}, ...rest } = await ctx.fetchCompositeJSON.fetchComposite();

        Object.assign(ctx.data, {
            ...rest,
            ...base,
            phrase: {
                ...summaryContent,
            },
        });
    };
}
