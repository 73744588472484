export default {
    sedan: {
        code: 'SEDAN',
        label: 'Sedans',
        link: '/sedan',
        displayName: 'Sedan',
        src: '/content/static/img/fba/landing/new_default_desk_style_fusion_sedan.png',
        type: 'bodyStyle',
    },
    truck: {
        code: 'TRUCKS',
        label: 'Trucks',
        link: '/truck',
        displayName: 'Truck',
        src: '/content/static/img/fba/landing/new_default_desk_style_F150_truck.png',
        type: 'bodyStyle',
    },
    'suv-crossover': {
        code: 'SUVCROSS',
        label: 'SUVs and Crossovers',
        link: '/suv-crossover',
        displayName: 'SUV/Crossover',
        src: '/content/static/img/fba/landing/new_default_desk_style_explorer_suv.png',
        type: 'bodyStyle',
    },
    coupe: {
        code: 'COUPE',
        label: 'Coupes',
        link: '/coupe',
        displayName: 'Coupe',
        src: '/content/static/img/fba/landing/new_default_desk_style_mustang_coupe.png',
        type: 'bodyStyle',
    },
    hatchback: {
        code: 'HATCH',
        label: 'Hatchbacks',
        link: '/hatchback',
        displayName: 'Hatchback',
        src: '/content/static/img/fba/landing/new_default_desk_style_focus_hatchback.png',
        type: 'bodyStyle',
    },
    'van-minivan': {
        code: 'VANMV',
        label: 'Vans and Minivans',
        link: '/van-minivan',
        displayName: 'Van/Minivan',
        src: '/content/static/img/fba/landing/new_default_desk_style_transit_van.png',
        type: 'bodyStyle',
    },
    convertible: {
        code: 'CONVERT',
        label: 'Convertibles',
        link: '/convertible',
        displayName: 'Convertible',
        src: '/content/static/img/fba/landing/new_default_desk_style_mustang_convertible.png',
        type: 'bodyStyle',
    },
    wagon: {
        code: 'WAGON',
        label: 'Wagons',
        link: '/wagon',
        displayName: 'Wagon',
        src: '/content/static/img/fba/landing/new_default_desk_style_wagon.png',
        type: 'bodyStyle',
    },
};
