import flpContentDuck from '@/ducks/flp/flpContentDuck';

export default function dispatchFordLandingPageData() {
    return async (ctx) => {

        const { data = {} } = ctx;

        ctx.store.dispatch(flpContentDuck.creators.assign({
            responsive: true,
            metaDescription: data.metaDescription,
            metaKeywords: data.metaKeywords || '',
            metaRobots: data.metaRobots,
            windowTitle: data.windowTitle || data.title,
            openGraphDescription: data.openGraphDescription,
            openGraphImage: data.openGraphImage,
            openGraphSiteName: data.openGraphSiteName,
            openGraphTitle: data.windowTitle || data.title,
            openGraphType: data.openGraphType,
            openGraphUrl: data.openGraphUrl,
            alternateUrl: data.alternateUrl,
        }));

    };
}
