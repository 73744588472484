import { useRouter } from 'next/router';

export default function useAnalyticsPageEventListener() {

    const router = useRouter();

    return {
        on: router.events && router.events.on,
        off: router.events && router.events.off,
        pageExitEvent: 'routeChangeStart',
    };
}
