import React from 'react';

import Link from 'next/link';

const goldCertCardContent = {
    alt: 'Ford Explorer® SUV alongside a Gold Certified banner',
    image: 'content/static/img/fba/landing/gold_cert.webp',
    heading: 'Gold Certified',
    section: 'gold',
    description: (onClick) => (
        <p>
            Gold Certified vehicles are up to six years old with less than 80,000 miles and have passed a 172-point inspection. Only Ford models, such as the
            {' '}
            <Link
                href="/all/ford/f150"
                onClick={onClick}
            >
                F-150® truck
            </Link>
            ,
            {' '}
            <Link
                href="/all/ford/f250"
                onClick={onClick}
            >
                F-250® truck
            </Link>
            , and
            {' '}
            <Link
                href="/all/ford/explorer"
                onClick={onClick}
            >
                Explorer® SUV
            </Link>
            , can become Gold Certified.
        </p>
    ),
};

const blueCertCardContent = {
    alt: 'Ford Mustang® coupe alongside a Blue Certified banner',
    image: 'content/static/img/fba/landing/blue_cert.webp',
    heading: 'Blue Certified',
    section: 'blue',
    description: (onClick) => (
        <p>
            Blue Certified vehicles are up to 10 years old with less than 150,000 miles and have passed a 139-point inspection. Blue Certified vehicles can be Ford and non-Ford makes and models, so you can find a variety of certified used vehicles, including
            {' '}
            <Link
                href="/all/suv-crossover"
                onClick={onClick}
            >
                SUVs
            </Link>
            ,
            {' '}
            <Link
                href="/all/truck"
                onClick={onClick}
            >
                trucks
            </Link>
            {' '}
            and
            {' '}
            <Link
                href="/all/commercial-vehicles"
                onClick={onClick}
            >
                commercial vehicles
            </Link>
            {' '}
            as part of the Ford Blue Advantage program.
        </p>
    ),
};

const evCertCardContent = {
    alt: 'Ford Mustang® EV alongside a EV Certified banner',
    image: 'content/static/img/fba/landing/ev_cert.webp',
    heading: 'EV Certified',
    section: 'ev',
    description: (onClick) => (
        <p>
            EV Certified vehicles are up to six years old with less than 80,000 miles and have passed a 127-point inspection. Only Ford models, such as the
            {' '}
            <Link
                href="/all/ford/mustang-mach-e"
                onClick={onClick}
            >
                Mustang MachE
            </Link>
            , and F150 Lightning, can become EV Certified.
        </p>
    ),
};

const fbaCertifiedCardContent = [
    goldCertCardContent,
    evCertCardContent,
    blueCertCardContent,
];

export default fbaCertifiedCardContent;
