module.exports = {
    options: [
        {
            label: 'Any Make',
            value: '',
        },
        {
            optgroup: 'All Makes',
            options: [
                { label: 'Acura', value: 'ACURA' },
                { label: 'Audi', value: 'AUDI' },
                { label: 'BMW', value: 'BMW' },
                { label: 'Buick', value: 'BUICK' },
                { label: 'Cadillac', value: 'CAD' },
                { label: 'Chevrolet', value: 'CHEV' },
                { label: 'Chrysler', value: 'CHRY' },
                { label: 'Dodge', value: 'DODGE' },
                { label: 'FIAT', value: 'FIAT' },
                { label: 'Ford', value: 'FORD' },
                { label: 'Genesis', value: 'GENESIS' },
                { label: 'GMC', value: 'GMC' },
                { label: 'Honda', value: 'HONDA' },
                { label: 'Hyundai', value: 'HYUND' },
                { label: 'INFINITI', value: 'INFIN' },
                { label: 'Jaguar', value: 'JAG' },
                { label: 'Jeep', value: 'JEEP' },
                { label: 'Kia', value: 'KIA' },
                { label: 'Lexus', value: 'LEXUS' },
                { label: 'MAZDA', value: 'MAZDA' },
                { label: 'Mercedes-Benz', value: 'MB' },
                { label: 'Mitsubishi', value: 'MIT' },
                { label: 'Nissan', value: 'NISSAN' },
                { label: 'RAM', value: 'RAM' },
                { label: 'Scion', value: 'SCION' },
                { label: 'smart', value: 'SMART' },
                { label: 'Subaru', value: 'SUB' },
                { label: 'Toyota', value: 'TOYOTA' },
                { label: 'Volkswagen', value: 'VOLKS' },
                { label: 'Volvo', value: 'VOLVO' },
            ],
        },
    ],
};
