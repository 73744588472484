import React from 'react';

import {
    ColoredBackground,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';

// component
import CrawlPathLinks from '@/containers/CrawlPathLinks';

const FordLandingCrawlPathFooterContainer = (props) => {
    const {
        seo_links: [isSeoLinksEnabled] = [],
    } = useFeatures(['seo_links']);

    const crawlpathLinks = () => isSeoLinksEnabled && (
        <ColoredBackground uiContext="default">
            <div className="margin-horizontal-5">
                <CrawlPathLinks />
            </div>
        </ColoredBackground>
    );
    return (
        crawlpathLinks()
    );
};

export default FordLandingCrawlPathFooterContainer;
