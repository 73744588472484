import _get from 'lodash/get';

import { BonnetModule } from '@bonnet/next/modules';

import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

const CookieConsentModule = new BonnetModule({
    duck: objectDuckCreator({
        store: 'cookieConsent',
        initialState: {},
    }).extend({
        selectors: (duck) => ({
            selectGroups: new DuckSelector((selectors) => createSelector(
                selectors.getDuckState,
                (state = {}) => state.groups,
            )),
            selectHasSuppressedGroup: new DuckSelector((selectors) => createSelector(
                selectors.selectGroups,
                (groups = {}) => Object.values(groups).some((value) => value === false),
            )),
        }),
    }),

    ctxMiddleware: ({ duck }) => async (ctx) => {
        // we only want to update the redux state for cokkie consent on the server request
        if (ctx.req) {
            ctx.batchDispatch.createActions(async (deferredCtx) => duck.creators.setKeys(_get(deferredCtx, 'req.onetrust', {})));
        }
    },
});

export default CookieConsentModule;
