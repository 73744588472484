import React from 'react';

import { useSelector } from 'react-redux';

import { AnalyticsEngine } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import fbaAnalyticsHandlers from '@/analytics/fbaAnalyticsHandlers';
import globalAnalyticsHandlers from '@/analytics/globalAnalyticsHandlers';
import useAnalyticsPageEventListener from '@/analytics/useAnalyticsPageEventListener';
import useDataLayer from '@/analytics/useDataLayer';

import { birfDuck } from '@/ducks';

import { CookieConsentModule } from '@/modules';

function FBALandingAnalytics() {

    const {
        disable_thirdparty: [disableThirdParty],
        google_analytics: [, { disableGtm, disableGtmEvents, delay }],
        oneTrust: [isOneTrustEnabled],
    } = useFeatures([
        'disable_thirdparty',
        'google_analytics',
        'oneTrust',
    ]);

    const hasSuppressedCookies = useSelector(CookieConsentModule.duck.selectors.selectHasSuppressedGroup) && isOneTrustEnabled;
    const analyticsPageEventListener = useAnalyticsPageEventListener();
    const dataLayer = useDataLayer();
    const dataIsland = useSelector(birfDuck.selectors.getPageData);

    const analyticsProps = {
        disabled: hasSuppressedCookies,
        dataLayer: () => dataLayer,
        getDataIsland: () => ({ ...dataIsland }),
        google: {
            delay,
        },
        eventListener: analyticsPageEventListener,
        value: {
            ...globalAnalyticsHandlers,
            ...fbaAnalyticsHandlers,
        },
        option: {
            isOptimizelyEdge: true,
            disableGTM: disableGtm || disableThirdParty,
            disableGTMEvents: disableGtmEvents,
            disablePixall: disableThirdParty,
        },
    };

    return (<AnalyticsEngine {...analyticsProps} />);
}

export default React.memo(FBALandingAnalytics);
