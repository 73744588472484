import { getReference } from '@atc/bonnet-reference';

import sortArrayByProperty from '@/utilities/sortUtils';

import fordSeoUrl from '@/config/seo-config-url/branded/ford';

export const defaultModelOption = [
    {
        label: 'Any Model',
        value: '',
    },
];

const sortOptionsByLabel = (opts = []) => sortArrayByProperty('label', opts);

export const getModelsByMakeCode = async (makeCode) => {

    if (makeCode) {
        const { payload: modelCodes } = await getReference('modelCode', { makeCode });

        if (modelCodes && modelCodes.length > 0) { // rename search options data: name => label, code => value
            const models = modelCodes.map((md) => ({ label: md?.name, value: md?.code }));

            return [
                ...defaultModelOption,
                {
                    optgroup: 'All Models',
                    options: sortOptionsByLabel(models),
                },
            ];
        }
    }
    return defaultModelOption;
};

export const buildFordLandingPageData = async () => {

    const pageData = {
        metaDescription: 'Search certified used cars, trucks, SUVs, and commercial vehicles backed by Ford. Shop certified used vehicles for sale near you.',
        openGraphDescription: 'Search certified used cars, trucks, SUVs, and commercial vehicles backed by Ford. Shop certified used vehicles for sale near you.',
        openGraphImage: process.env.NODE_ENV === 'development' ? fordSeoUrl.nonProd.landingPageOGImage : fordSeoUrl.prod.landingPageOGImage,
        openGraphSiteName: 'Ford Blue Advantage',
        openGraphType: 'website',
        openGraphUrl: process.env.NODE_ENV === 'development' ? fordSeoUrl.nonProd.landingPageOGUrl : fordSeoUrl.prod.landingPageOGUrl,
        windowTitle: 'Shop Ford Blue Advantage Certified Used Vehicles',
    };
    return pageData;
};

export default {
    buildFordLandingPageData,
    defaultModelOption,
    getModelsByMakeCode,
};
